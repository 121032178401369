<template>
  <v-container>
    <v-card max-width="800" class="mt-10 mb-10 mx-auto">
      <v-card-title> تسجيل حساب جديد </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <v-text-field :rules="rules.name" v-model="sign_data.name">
                <template slot="label">
                  <strong class="red--text">*</strong>
                  الأسم
                </template>
              </v-text-field>
            </v-col>
            <!--/ col -->

            <v-col cols="12" sm="6">
              <v-text-field
                @keypress="email_exist = false"
                :rules="rules.email"
                v-model="sign_data.email"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  البريد الالكتروني
                </template>
              </v-text-field>
            </v-col>
            <!--/ col -->

            <v-col cols="12" sm="6">
              <v-text-field
                @keypress="phone_exist = false"
                :rules="rules.phone"
                v-model="sign_data.phone"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  رقم الهاتف
                </template>
              </v-text-field>
            </v-col>
            <!--/ col -->

            <v-col cols="12" sm="6">
              <v-text-field
                :rules="rules.password"
                type="password"
                v-model="sign_data.password"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  كلمة المرور
                </template>
              </v-text-field>
            </v-col>
            <!--/ col -->

            <v-col cols="12" sm="6">
              <v-text-field
                :rules="rules.c_password"
                type="password"
                v-model="sign_data.c_password"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  تكرار كلمة المرور
                </template>
              </v-text-field>
            </v-col>
            <!--/ col -->
            <v-col cols="12" sm="6">
              <v-file-input
                :rules="rules.front_img"
                v-model="sign_data.front_ing"
                accept="image/*"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  الصورة الأمامية للبطاقةالشخصية
                </template>
              </v-file-input>
            </v-col>

            <!--/ col -->
            <v-col cols="12" sm="6">
              <v-file-input
                :rules="rules.back_img"
                accept="image/*"
                v-model="sign_data.back_img"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  الصورة الخلفية للبطاقةالشخصية
                </template>
              </v-file-input>
            </v-col>
            <!--/ col -->
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :loading="save_loading"
          :disabled="save_loading"
          @click="sign_up()"
          color="success"
          >تسجيل الحساب</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-dialog  hide-overlay  max-width="500" v-model="dialog">
      <v-card>
        <v-card-text class="pt-5">
          <v-alert
            dense
            color="success"
            class="text-center white--text mt-5 align-center"
            >تم تسجيل الحساب نجاح وسيتم مراجعة طلب التسجيل من قبل الأدارة
          </v-alert>

          <LottieAnimation
            ref="anim"
            :animationData="require('@/assets/login/57490-successful.json')"
            :loop="true"
            style="width: 50%; margin: 50px auto"
          ></LottieAnimation>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" @click="dialog = false">تم</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
export default {
  name: "signup",
  data() {
    return {
      dialog: false,
      save_loading: false,
      rules: {
        name: [
          (v) => !!v || "الأسم مطلوب",
          (v) =>
            /^[آ-یA-z]{2,}( [آ-یA-z]{2,})+([آ-یA-z]|[ ]?)$/.test(v) ||
            "كتابة الأسم الاول والثانى على الأقل",
        ],
        email: [
          (v) => !!v || "البريد الالكتروني مطلوب",
          (v) => /.+@.+\..+/.test(v) || "البريد غير صحيح",
          (v) => !this.email_problem || "هذا البريد موجود مسبقا",
        ],
        phone: [
          (v) => !!v || "رقم الهاتف مطلوب",
          (v) => /(01)[0-9]{9}/.test(v) || "رقم الهاتف غير صحيح",
          (v) => !this.phone_problem || "هذا الهاتف موجود مسبقا",
        ],
        password: [
          (v) => !!v || "كلمة المرور مطلوب",
          (v) => !(v.toString().length < 8) || "يجب ان يكون 8 او كثر",
        ],
        c_password: [(v) => !!this.not_mutch || "غير متطابقة"],
        front_img: [(v) => !!v || "الصورة الامامية مطلوبة"],
        back_img: [(v) => !!v || "الصورة الامامية مطلوبة"],
      },
      email_exist: false,
      phone_exist: false,
      sign_data: {
        name: "",
        email: "",
        phone: "",
        front_ing: null,
        back_img: null,
        password: "",
        c_password: "",
      },
    };
  },
  components: {
    LottieAnimation,
  },
  computed: {
    email_problem() {
      return this.email_exist;
    },
    phone_problem() {
      return this.phone_exist;
    },
    not_mutch() {
      return this.sign_data.c_password == this.sign_data.password;
    },
  },
  methods: {
    sign_up() {
      if (this.$refs.form.validate()) {
        this.save_loading = true;
        const formData = new FormData();

        Object.keys(this.sign_data).forEach((e, i) => {
          formData.append(e, this.sign_data[e]);
        });

        this.$store
          .dispatch("public__request", {
            config: {
              url: "register",
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            // console.log(res);
            this.save_loading = false;
            this.sign_data = {
              name: "",
              email: "",
              phone: "",
              front_ing: null,
              back_img: null,
              password: "",
              c_password: "",
            };
            this.dialog = true;
            this.$refs.form.resetValidation();
          })
          .catch((err) => {
            // console.log(err.response);
            this.save_loading = false;
            if (err.response.data.data.email) {
              this.email_exist = true;
            }
            if (err.response.data.data.phone) {
              this.phone_exist = true;
            }
            this.$refs.form.validate();
          });
      }
    },
  },
};
</script>
